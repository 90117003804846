.p-card-title {
  color: #614200;
  font-weight: 500;
  font-size: 18pt;
}
.p-orange {
  color: #614200;
}
.p-red {
  color: #bd8600;
  font-size: 1.5em;
}
.p-menu-custom {
  color: white;
  font-size: 0.8em;
}
.p-button.p-button-text {
  background-color: transparent;
  color: white;
  border-color: transparent;
}
.p-logo {
  color: rgb(252, 213, 25);
}
.p-news-img {
  margin-right: 1em;
  margin-left: 1em;
  /*  width: 350px;  
  display: block;
 */
}
@media screen and (min-width: 501px) {
  .p-news-img {
    width: 300px;
    height: auto
  }
}

@media screen and (max-width: 500px) {
  .p-news-img {
    width: 200px;
    height: auto
  }
}
.p-mylink {
  color: #614200;
  text-decoration: underline;
}
.p-addlink {
  color: #0f0f0f;
  font-size: 0.8em;
}
.p-page-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 501px) {
  .p-page-img {
    width: 400px;
    height: auto;
  }
}

@media screen and (max-width: 500px) {
  .p-page-img {
    width: 250px;
    height: auto;
  }
}

.p-team-card-text {
  font-family: Roboto, "Helvetica Neue Light", "Helvetica Neue", Helvetica,
    Arial, "Lucida Grande", sans-serif;
}

@media screen and (min-width: 501px) {
  .p-team-card-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .p-team-card-text {
    font-size: 1vw;
  }
}

@media screen and (max-width: 765px) {
  .p-displayLogo {
    display: none;
  }
}
